import VueRouter from 'vue-router'
import Book from './Book.vue'
import Index from './Index.vue'

const routes = [
  { path: "/", component: Index },
  { path: "/book/gold-book-of-lviv/", component: Book, 'name': 'book' },
  { path: "/book/gold-book-of-lviv/:page", component: Book, 'name': 'book-page' }
]

export default new VueRouter({ routes })
