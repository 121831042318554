<template>
    <div class="book-intro">
        <div class="institution">
            Державна архівна служба України<br>
            Центральний державний історичний архів України, м.Львів
        </div>
        <h1 class="title">{{ book.title }}</h1>
        <div class="about">{{ book.description }}</div>
        <router-link class="button" to="/book/gold-book-of-lviv/">
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: white;"><path d="M21 3h-7a2.98 2.98 0 0 0-2 .78A2.98 2.98 0 0 0 10 3H3a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h5.758c.526 0 1.042.214 1.414.586l1.121 1.121c.009.009.021.012.03.021.086.079.182.149.294.196h.002a.996.996 0 0 0 .762 0h.002c.112-.047.208-.117.294-.196.009-.009.021-.012.03-.021l1.121-1.121A2.015 2.015 0 0 1 15.242 20H21a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.758 18H4V5h6c.552 0 1 .449 1 1v12.689A4.032 4.032 0 0 0 8.758 18zM20 18h-4.758c-.799 0-1.584.246-2.242.689V6c0-.551.448-1 1-1h6v13z"></path></svg>
            <span>
                Переглянути
            </span>
        </router-link>
        <ul class="logos">
            <li><a href="https://archives.gov.ua"><img src="/assets/logo-archives.svg" alt="Державна архівна служба України"></a></li>
            <li><a href="https://archives.gov.ua"><img src="/assets/logo-lviv.svg" alt="Центральний державний історичний архів України, м.Львів"></a></li>
        </ul>
        <video class="backdrop" src="/assets/book-3d.mp4" muted autoplay loop playsinsline></video>
    </div>
</template>

<script>
 import Books from './data/books.yml'
 const book = Books[0];

 export default {
     data() {
         return { book }
     },
 }
</script>

<style lang="scss">
 .book-intro {
     padding: 3vw;
     overflow-y: auto;
     max-height: 100%;
     max-width: 40rem;
     font-weight: normal;
     @media (max-width: 600px) {
         font-size: 80%;
         padding: 6vw;
         text-align: center;
         .title {
             font-weight: bold;
         }
     }
     @media (min-width: 1000px) {
         .title {
             font-size: 4rem;
         }
     }
 }
 .institution {
     text-transform: uppercase;
     font-size: 90%;
 }
 .about {
     text-align: justify;
     hyphens: auto;
     margin-bottom: 1rem;
 }
 .backdrop {
     position: fixed;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     object-fit: cover;
     width: 100%;
     height: 100%;
     z-index: -5;
 }
 .button {
     display: inline-flex;
     color: white;
     text-transform: uppercase;
     border: 3px solid rgba(196, 45, 45, 0.5);
     padding: 0.5em;
     text-decoration: none;
     flex-flow: row;
     margin: 0.5em 0;
     align-items: center;
     font-weight: 300;
     background: rgba(255, 124, 124, 0.1);
     color: white;
     transition: background-color 0.5s, border-color 0.5s;
     > span {
         display: block;
         padding-left: 0.5em;
     }
     &:hover, &:focus {
         background-color: transparentize(white, 0.5);
         border-color: transparentize(white, 0.2);
     }
 }
 .logos {
     list-style: none;
     margin: 0;
     padding: 0;
     display: flex;
     flex-flow: row;
     align-items: center;
     > li {
         padding: 0;
         img {
             max-height: 14rem;
         }
     }
     > li:nth-child(1) {
         img {
             opacity: 0.8;
             max-height: 9rem;
         }
     }
 }
</style>
