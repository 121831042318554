<template>
    <div>
        <img class="vignette" alt="" src="/assets/vignette.png">
        <img class="logo" src="/assets/zkl-gold.png" alt="з к л">
        <transition name="fade">
            <dialog class="audio-permission" open v-if="!audioPermission && !audioDismissed">
                <span class="message">Дозволити відтворення музичної доріжки?</span> <button class="positive" @click="initAudio">Так</button> <button @click="audioDismissed = true">Ні, дякую</button>
            </dialog>
        </transition>
        <transition name="fade-slow">
            <router-view></router-view>
        </transition>
        <audio ref="soundtrack" src="/assets/soundtrack.mp3"
               @play="onPlayPause" @pause="onPlayPause">
        </audio>
    </div>
</template>

<script>
 import router from './router.js'
 
 export default {
     router,
     data () {
         return {
             ostIsPaused: true,
             audioPermission: false,
             audioDismissed: false,
         }
     },
     methods: {
         initAudio () {
             this.audioPermission = true;
             this.playPause();
         },
         onPlayPause (evt) {
             this.ostIsPaused = evt.target.paused;
         },
         playPause () {
             const ost = this.$refs.soundtrack;
             ost.paused ? ost.play() : ost.pause();
         },
     },
 }
</script>

<style lang="scss">
 body {
     font-family: "Montserrat";
     font-weight: 300;
     background: black;
     color: white;
     background: url('/assets/bg.jpg');
     background-size: 140%;
     font-size: 140%;
     margin: 0;
     padding: 0;
     @media (min-width: 1000px) {
         font-weight: 100;
     }
 }
 .music {
     position: fixed;
     right: 1rem;
     top: 30%;
     border: none;
     background: none;
 }
 .audio-permission {
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     z-index: 500;
     padding: 0.5rem;
     color: white;
     border: 0;
     background: transparentize(black, 0.3);
     border: none;
     font-size: 0.8rem;
     font-weight: normal;
     margin: 0;
     width: 100%;
     text-align: center;
     > .message {
         margin-right: 1rem;
     }
     > .positive {
         background: white;
         color: black;
     }
 }

 .logo {
     position: fixed;
     right: 0.5rem;
     bottom: 0.5rem;
     width: 2.5rem;
     @media (min-width: 1000px) {
         width: 4rem;
     }
 }

 .vignette {
     pointer-events: none;
     display: block;
     position: fixed;
     top: 0;
     right: 0;
     left: 0;
     bottom: 0;
     width: 100%;
     height: 100%;
     z-index: 300;
     object-fit: cover;
     opacity: 0.35;
 }

 .fade-slow-enter-active, .fade-slow-leave-active {
     transition: opacity 2s;
 }
 .fade-slow-enter, .fade-slow-leave-active {
     opacity: 0
 }
</style>
