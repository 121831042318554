<template>
    <div id="app" class="clip" @mousemove="mouse" ref="app">
      <flipbook
          class="flipbook"
          :pages="getPages(book)"
          :pagesHiRes="getPages(book, true)"
          :gloss="0"
          :startPage="startPage"
          :loadingImage="'/assets/book/p01.jpg'"
          ref="flipbook"
          v-slot="flipbook"
          @flip-left-start="flipSound"
          @flip-right-start="flipSound"
          @flip-left-end="setPage"
          @flip-right-end="setPage"
      >
          <router-link class="back" aria-label="Назад" title="Назад" to="/">
              <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style="fill: white;transform: ;msFilter:;"><path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path></svg>
          </router-link>
          <button class="fullscreen" aria-label="на повний екран" title="розгорнути" @click="fullscreen">
              <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style="fill: white;transform: ;msFilter:;"><path d="M5 12H3v9h9v-2H5zm7-7h7v7h2V3h-9z"></path></svg>
          </button>
          <dl class="hud">
              <dt aria-label="сторінка"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: white;"><path d="M21 3h-7a2.98 2.98 0 0 0-2 .78A2.98 2.98 0 0 0 10 3H3a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h5.758c.526 0 1.042.214 1.414.586l1.121 1.121c.009.009.021.012.03.021.086.079.182.149.294.196h.002a.996.996 0 0 0 .762 0h.002c.112-.047.208-.117.294-.196.009-.009.021-.012.03-.021l1.121-1.121A2.015 2.015 0 0 1 15.242 20H21a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.758 18H4V5h6c.552 0 1 .449 1 1v12.689A4.032 4.032 0 0 0 8.758 18zM20 18h-4.758c-.799 0-1.584.246-2.242.689V6c0-.551.448-1 1-1h6v13z"></path></svg></dt>
              <dd>{{ pgFmt(flipbook.page) }}</dd>
              <template v-if="meta">
                  <dt aria-label="Роки"><svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: white;"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="M13 7h-2v5.414l3.293 3.293 1.414-1.414L13 11.586z"></path></svg></dt>
                  <dd class="range">
                      <span class="from">{{ meta.year }}</span>
                  </dd>
              </template>
          </dl>
          <ul class="dock">
              <li>
                  <button :disabled="!meta"
                          aria-label="Переклад"
                          title="Переклад"
                          :aria-expanded="showTranslation"
                          aria-controls="translation"
                          @click="showTranslation = !showTranslation">
                      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style="fill: white;transform: ;msFilter:;"><path d="M4 19h16v2H4zm0-4h11v2H4zm0-4h16v2H4zm0-8h16v2H4zm0 4h11v2H4z"></path></svg>
                  </button>
                  <button aria-label="Список перекладів"
                          title="Список перекладів"
                          :aria-expanded="showTranslationList"
                          aria-controls="translation-list"
                          @click="showTranslationList = !showTranslationList">
                      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style="fill: white;transform: ;msFilter:;"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="m8 16 5.991-2L16 8l-6 2z"></path></svg>
                  </button>
              </li>
          </ul>

          <button @click="flipbook.flipLeft" class="control flip-prev">
              <img src="/assets/arrow.svg" alt="попередня">
          </button>
          <button @click="flipbook.flipRight" class="control flip-next">
              <img src="/assets/arrow.svg" alt="наступна">
          </button>

          <transition name="reveal">
              <aside id="description" class="description" v-if="showDescription && meta && !showTranslation && !showTranslationList" aria-label="Коментар">
                  <div class="inner">
                      {{ meta.comment }}
                  </div>
              </aside>
          </transition>
          <transition name="fade">
              <div id="translation-list" class="translation-list" v-if="showTranslationList">
                  <nav class="inner">
                      <h2>Список перекладів</h2>
                      <ul>
                          <li v-for="meta in book.meta" :key="meta.from">
                              <router-link :to="{ name: 'book-page', params: { 'page': meta.from } }"
                                           @click.native="() => { jumpToPage(meta.from); showTranslationList = false }">
                                  {{ meta.comment.substring(0, 40) }}&hellip;
                              </router-link>
                          </li>
                      </ul>
                  </nav>
              </div>
          </transition>
          <transition name="fade">
              <aside v-if="showTranslation && meta" id="translation" class="translation">
                  <button class="close" aria-label="Закрити" @click="showTranslation = false">&times;</button>
                  <h2 class="title">Переклад</h2>
                  <VueMarkdown class="inner" :source="meta.translation" :breaks="false"></VueMarkdown>
              </aside>
          </transition>
      </flipbook>
  </div>
</template>

<script>
 import Flipbook from 'flipbook-vue'
 import Pizzicato from 'pizzicato'
 import VueMarkdown from '@adapttive/vue-markdown'
 import Books from './data/books.yml'

 const PAGE_OFFSET = -5;
 const PAGE_PREFIX = "/assets/book/";
 const PAGE_PREFIX_HIRES = "/assets/book/hi-res/";
 const DEFAULT_START_PAGE = 1;

 let noise = null;
 let noiseLoaded = false;

 export default {
     name: 'Book',
     components: {
         Flipbook,
         VueMarkdown,
     },
     data: () => {
         return {
             book: Books[0],
             showTranslation: false,
             showDescription: true,
             showTranslationList: false,
             zoomed: false,
             page: null,
             startPage: DEFAULT_START_PAGE,
         }
     },
     computed: {
         meta () {
             if (this.page) {
                 return this.metaForPage(this.page);
             } else {
                 return null;
             }
         },
     },
     created() {
         noise = new Pizzicato.Sound('/assets/page-turn.wav', () => noiseLoaded = true);
     },
     mounted () {
         const page = Number(this.$route.params.page);
         if (page) {
             this.startPage = page;
             this.setPage(page);
         }
     },
     methods: {
         setPage(pnum) {
             this.page = pnum;
             this.$router.push({ name: 'book-page', params: { page: pnum }});
         },
         getPages(book, hires = false) {
             return book.pages.map(p => (hires ? PAGE_PREFIX_HIRES : PAGE_PREFIX) + p);
         },
         metaForPage(page) {
             const real = this.bookPage(page);
             return this.book.meta.find(el => el.from <= real && el.to >= real);
         },
         jumpToPage(bookPage) {
             const page = bookPage - PAGE_OFFSET;
             // make sure we always jump to an odd page
             const odd = page % 2 ? page : page + 1;
             this.startPage = odd;
             this.setPage(odd);
         },
         fullscreen: function () {
             if (document.fullscreenElement)
                 document.exitFullscreen()
             else this.$refs.app.requestFullscreen();
         },
         bookPage: pagenum => pagenum + PAGE_OFFSET,
         pgFmt: function (pagenum) {
             const real = this.bookPage(pagenum);
             if (real == 0) {
                 return '1';
             } else if (real > 0) {
                 return `${real}/${real + 1}`;
             } else {
                 return '-';
             }
         },
         flipSound () {
             if (this.$root.$data.audioPermission && noiseLoaded)
                 noise?.play();
         },
         mouse (evt) {
             document.body.style.backgroundPosition = `${(evt.clientX / 30) * -1}px ${(evt.clientY / 30) * -1}px`;
         },
     },
 }
</script>

<style lang="scss">
 @import "~@fontsource/montserrat/100.css";
 @import "~@fontsource/montserrat/300.css";

 $dur-1: 0.75s;
 $gap: 2rem;
 $small-gap: 0.75rem;

 h1, h2, h3 {
     font-weight: 100;
     text-transform: uppercase;
 }

 h2 {
     font-size: 1.5rem;
 }

 a {
     color: white;
     font-weight: bold;
     text-decoration: none;
 }

 button {
     display: inline-block;
     padding: 0.5rem;
     background: transparentize(black, 0.5);
     color: white;
     text-transform: uppercase;
     border: 1px solid #eee;
     transition: filter $dur-1;
     &:not([disabled]):hover, &:not([disabled]):focus {
         filter: drop-shadow(0 0 12px white);
     }
     &[disabled] {
         opacity: 0.5;
     }
     &:not([disabled]) {
         cursor: pointer;
     }
 }

 .clip {
     position: fixed;
     left: 0;
     right: 0;
     top: 0;
     bottom: 0;
     width: 100%;
     height: 100%;
     overflow: hidden;
 }
 .flipbook {
     margin-top: 2vh;
     width: 100vw;
     height: 95vh;
     .viewport.zoom {
         height: 100vh !important;
     }
 }
 .description, .translation-list {
     position: fixed;
     bottom: 6vh;
     left: 0;
     right: 0;
     display: flex;
     flex-flow: column;
     align-items: center;
     justify-content: center;
     z-index: 5;
     > .inner {
         font-size: 85%;
         font-weight: normal;
         text-align: center;
         max-width: 60ch;
         margin: 0;
         background: transparentize(black, 0.35);
         border-radius: 1rem;
         color: white;
         padding: 0.5rem;
         box-shadow: 0 0 12px transparentize(black, 0.3);
     }
     button {
         border: none;
         background: 0;
         display: inline-flex;
         align-items: center;
     }
 }
 .translation-list > .inner > ul {
     text-align: left;
 }
 .fullscreen, .back {
     position: fixed;
     top: $small-gap;
     border: none;
     background: none;
     z-index: 299;
     > svg {
         width: 24px;
         @media (min-height: 700px) {
             width: 36px;
         }
     }
 }
 .fullscreen {
     right: $small-gap;
 }
 .back {
     left: $small-gap;
 }
 .hud {
     position: fixed;
     top: $gap;
     left: $gap;
     bottom: $gap;
     width: 7vw;
     min-width: 4rem;

     display: flex;
     flex-flow: column;
     justify-content: center;

     @media (min-height: 700px) {
         font-size: 175%;
     }
     text-shadow: 0 0 16px green;
     text-align: center;
     > * {
         padding: 0;
         margin: 0;
     }
     dt {
         font-size: 65%;
         text-transform: lowercase;
         margin-top: $gap / 2;
         margin-bottom: $gap / 2;
         > svg {
             width: 24px;
         }
         @media (min-height: 700px) {
             margin-top: $gap * 2;
             margin-bottom: $gap;
             > svg {
                 width: 36px;
             }
         }
     }
     dd {
     }
     .range {
         display: flex;
         flex-flow: column;
         align-items: center;
     }
     .separator {
         font-size: 50%;
     }
 }
 .dock {
     list-style: none;
     margin: 0;
     padding: 0;
     position: fixed;
     top: 15%;
     bottom: 15%;
     right: $small-gap;
     z-index: 5;
     display: flex;
     flex-flow: column;
     align-items: center;
     justify-content: center;
     button {
         border: none;
         background: none;
         &[disabled] {
             opacity: 0;
             visibility: hidden;
         }
     }
 }
 .control {
     border: none;
     position: fixed;
     top: 38vh;
     background: none;
     z-index: 299;
     img {
         height: 15vh;
     }
     &.flip-prev {
         left: 14%;
         img {
             transform: rotate(-180deg);
         }
     }
     &.flip-next {
         right: 12%;
     }
 }
 .button-shape {
     stroke: white;
     stroke-width: 2;
     fill: transparentize(white, 1);
     transition: fill $dur-1;
     :hover > &, :focus > & {
         fill: transparentize(white, 0.5);
     }
 }
 .flip-next {
     > svg {
         transform: scale(-1, 1);
     }
 }
 .translation {
     box-sizing: border-box;
     position: fixed;
     top: 0;
     right: 0;
     bottom: 0;
     max-width: 40rem;
     width: 100%;
     background: transparentize(black, 0.2);
     padding: $gap;
     z-index: 305;
     overflow-y: auto;
     hyphens: auto;
     text-align: justify;
     > .close {
         position: fixed;
         top: $gap;
         right: $gap;
         background: none;
         border: none;
         color: white;
         font-size: 2rem;
     }
     > .inner {
         font-size: 1rem;
         @media (min-width: 700px) {
             font-size: 1.25rem;
         }
     }
 }
 .translation-trigger {
     position: fixed;
     top: 5%;
     left: 20%;
     z-index: 160;
 }
 .reveal-enter-active, .reveal-leave-active {
     transition: opacity .5s, transform .5s;
 }
 .reveal-enter, .reveal-leave-to /* .fade-leave-active below version 2.1.8 */ {
     opacity: 0;
     transform: translateY(-50px);
 }

 .fade-enter-active, .fade-leave-active {
     transition: opacity 0.75s;

 }
 .fade-enter, .fade-leave-active {
     opacity: 0
 }

</style>
